exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-kontakta-oss-tack-js": () => import("./../../../src/pages/kontakta-oss/tack.js" /* webpackChunkName: "component---src-pages-kontakta-oss-tack-js" */),
  "component---src-pages-verktyg-digital-marknadsforing-index-js": () => import("./../../../src/pages/verktyg-digital-marknadsforing/index.js" /* webpackChunkName: "component---src-pages-verktyg-digital-marknadsforing-index-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-contact-page-js": () => import("./../../../src/templates/contactPage.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-erbjudande-js": () => import("./../../../src/templates/erbjudande.js" /* webpackChunkName: "component---src-templates-erbjudande-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-landing-page-specific-js": () => import("./../../../src/templates/landing-page-specific.js" /* webpackChunkName: "component---src-templates-landing-page-specific-js" */),
  "component---src-templates-privacy-policy-js": () => import("./../../../src/templates/privacyPolicy.js" /* webpackChunkName: "component---src-templates-privacy-policy-js" */),
  "component---src-templates-tack-js": () => import("./../../../src/templates/tack.js" /* webpackChunkName: "component---src-templates-tack-js" */),
  "component---src-templates-tools-js": () => import("./../../../src/templates/tools.js" /* webpackChunkName: "component---src-templates-tools-js" */),
  "component---src-templates-utan-kostnad-js": () => import("./../../../src/templates/utan-kostnad.js" /* webpackChunkName: "component---src-templates-utan-kostnad-js" */)
}

